import { env } from "md-base-tools/env";
import { postOrder } from "@/utils/apiBase.js";
import domain from '@/service/domain';
import { mapState } from "vuex";
import Error from "@@/common/Error.vue";
import lowerdev from "@/service/lowerdev";
import BigImage from '@@/luckycards/bigImage.vue';
import { getGoodsDetail, getAddressList } from "@/api";
import { showConfirmDialog, showSuccessToast, showToast } from 'vant';
import { throttle } from "@/utils/tools";
import ClipboardJS from "clipboard";
import { mdToast } from "../../utils/tools";
export default {
  components: {
    Error,
    BigImage
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    })
  },
  data() {
    return {
      env,
      showError: false,
      showMessage: "",
      miniProgram: "",
      pay_resource: 'weixin_mina',
      addressList: [],
      // 地址列表
      addressItem: {},
      // 选中地址
      openwxmina: false,
      html: '',
      iframe: false,
      iframeUrl: location.origin + "/luckycards/fastsell/openhtml",
      ifPayDown: false,
      deliverVisible: false,
      closeIcon: 'https://p6.moimg.net/path/dst_project/1063753/202211/0310/4400/22110344008lmwGOxRgXAKv73Jdjvrbd12V0p5j7.png',
      postage_order_no: this.$route.query.postage_order_no,
      order_detail: {},
      bigImageVisible: false,
      bigInitIndex: 0,
      bigList: [],
      copyboard: null
    };
  },
  beforeMount() {
    if (sessionStorage.getItem("miniProgram") === "weixin") {
      lowerdev.dynamicScript("https://res.wx.qq.com/open/js/jweixin-1.4.0.js");
    }
  },
  async mounted() {
    // eslint-disable-next-line no-undef
    userHeader.backnav.style.top = 0;
    // if (this.env.isModianIos() || this.env.isModianAndroid()) {
    //     // eslint-disable-next-line no-undef
    //     userHeader.backnavOnShow(true);
    // }
    window.userHeader.titleText("我的卡包");
    // 获取收货地址
    this.getAddress();
    await this.getInfo();
    this.miniProgram = sessionStorage.getItem("miniProgram") == "weixin";
    var target = this.$refs.copyboard;
    this.copyboard = new ClipboardJS(target, {
      text(target) {
        return target.getAttribute("copyData");
      }
    });
    this.copyboard.on("success", () => {
      // eslint-disable-next-line
      showSuccessToast("复制成功");
    });
    const that = this;
    window.addEventListener('pageshow', that.pageshow);
    document.addEventListener("visibilitychange", function () {
      // 用户息屏、或者切到后台运行 （离开页面）
      if (document.visibilityState === "hidden") {
        console.log("hidden");
      }
      // 用户打开或回到页面
      if (document.visibilityState === "visible") {
        console.log("页面可见");
        if (that.ifPayDown) {
          mdToast('操作成功');
          setTimeout(() => {
            window.location.reload();
          }, 200);
        }
      }
    });
  },
  unmounted() {
    // eslint-disable-next-line no-undef
    userHeader.backnav.style.top = "1.6rem";
  },
  beforeUnmount() {
    const that = this;
    window.removeEventListener('pageshow', that.pageshow);
  },
  methods: {
    pageshow(event) {
      if (event.persisted) {
        // ios 有效, android 和 pc 每次都是 false
        location.reload();
      }
    },
    // 获取list
    async getInfo() {
      let option = {
        postage_order_no: this.postage_order_no
      };
      const {
        status,
        data,
        message
      } = await getGoodsDetail(option);
      if (status != 0) {
        this.showError = true;
        this.showMessage = message || "暂无数据";
        return;
      } else {
        this.showError = false;
        this.order_detail = data;
      }
    },
    clickfn(type) {
      this.pay_resource = type;
    },
    // 切换地址
    goToAddressList() {
      sessionStorage.setItem('modify_address_detail', 'true');
      location.href = `${domain.wap}/address/address_list?redirectUrl=${encodeURIComponent(location.origin + location.pathname)}`;
    },
    // 获取默认地址接口
    async getAddress() {
      if (sessionStorage.getItem('address') && sessionStorage.getItem('modify_address_detail') == 'true') {
        const res = await getAddressList({
          user_id: this.userId
        });
        if (res.status == 0 && res.data.length) {
          this.addressList = res.data;
          this.addressItem = this.addressList[0];
          let item = JSON.parse(sessionStorage.getItem('address'));
          let index = this.addressList.findIndex(ele => ele.id == item.id);
          if (index > -1 || sessionStorage.getItem('modify_address_detail')) {
            this.addressItem = item;
            var postParams = {
              url: "/apis/card-wallet/modify/address",
              method: "post",
              props: {
                postage_order_no: this.postage_order_no,
                address: JSON.stringify(this.addressItem)
              }
            };
            sessionStorage.removeItem('modify_address_detail');
            const res = await postOrder(postParams.url, postParams.props);
            // .then((res) => {
            // console.log(res);
            if (res.status == 0) {
              sessionStorage.removeItem('modify_address_detail');
              mdToast('地址修改成功');
              // Dialog({
              //     message: '地址修改成功',
              //     confirmButtonColor:"#00C4A1"
              // })
              // .then(() => {
              //     window.location.reload();
              // });
            }
            // })
          }
        }
      }
    },
    hideCardDetail() {
      this.cardDetailVisible = false;
      this.card_detail_list = [];
    },
    rowScroll(e) {
      console.log(e);
    },
    getStatusTxt(code) {
      let status = '已完成';
      switch (code) {
        case 0:
          status = '待支付';
          break;
        case 10:
          status = '待发货';
          break;
        case 20:
          status = '已取消';
          break;
        case 30:
          status = '待收货';
          break;
        case 40:
          status = '待收货';
          break;
        case 50:
          status = '已完成';
          break;
        case 60:
          status = '已取消';
          break;
      }
      return status;
    },
    opOrder: throttle(async function (type) {
      switch (type) {
        // 取消发货单
        case 'cancel_deliver_goods':
          this.cancelOrderFun();
          break;
        // 去支付
        case 'to_pay':
          this.reSendPay();
          break;
        // 删除发货单
        case 'del_order':
          this.delOrderFun();
          break;
        // 修改地址
        case 'modify_address':
          this.goToAddressList();
          break;
        // 查看物流
        case 'query_express':
          this.$router.push({
            path: "/luckycards/logistics_list",
            query: {
              postage_order_no: this.postage_order_no
            }
          });
          break;
        default:
          mdToast('功能未开放');
      }
    }),
    async reSendPay() {
      let platform = '';
      if (this.env.isWeixin()) {
        platform = 'weixin';
      } else if (this.env.isWeibo()) {
        platform = 'weibo';
      } else {
        platform = 'alipay';
      }
      if (!this.env.isWeixin() && !this.env.isWeibo()) {
        if (this.pay_resource == 'weixin_mina') {
          platform = 'weixin';
        } else {
          platform = 'alipay';
        }
      }
      let channel = 'pc';
      if (sessionStorage.getItem("miniProgram") || this.env.isWxApp()) {
        channel = 'miniprogram';
      } else if (this.env.isWeixin()) {
        channel = 'weixin';
      } else if (this.env.isModianIos() || this.env.isModianAndroid() || this.env.isModianIosNew() || this.env.isMobile()) {
        channel = 'wap';
      } else {
        channel = 'pc';
      }
      let postParams = {
        url: "/apis/card-wallet/shipping_order/send_pay",
        method: "post",
        props: {
          postage_order_no: this.postage_order_no,
          platform: platform,
          channel: channel
        }
      };
      if (this.env.isWeixin() || this.env.isWxApp()) {
        postOrder(postParams.url, postParams.props).then(res => {
          if (sessionStorage.getItem("miniProgram") || this.env.isWxApp()) {
            // eslint-disable-next-line no-undef
            wx.miniProgram.navigateTo({
              url: "/pages/payPages/pay_h5/pay_h5?type=container&hasDataPay=" + encodeURIComponent(JSON.stringify(res.data.data))
            });
          } else {
            const json = {
              appId: res.data.data.appid || res.data.data.appId,
              timeStamp: res.data.data.timestamp,
              nonceStr: res.data.data.noncestr,
              package: res.data.data.package,
              signType: res.data.data.signtype,
              paySign: res.data.data.paysign
            };
            this.callWeixinPay(json);
          }
        });
      } else if (this.env.isWeibo()) {
        postOrder(postParams.url, postParams.props).then(res => {
          if (res) {
            window.location.href = res.data.data;
          } else {
            mdToast("支付失败");
          }
        });
      } else {
        this.deliverVisible = true;
      }
    },
    async delOrderFun() {
      showConfirmDialog({
        message: "删除的发货单将不可恢复",
        confirmButtonColor: "#00C4A1"
      }).then(() => {
        var postParams = {
          url: "/apis/card-wallet/del/postage_order",
          method: "post",
          props: {
            postage_order_no: this.postage_order_no
          }
        };
        postOrder(postParams.url, postParams.props).then(res => {
          console.log(res);
          if (res.status == 0) {
            showToast({
              type: "text",
              message: "删除成功",
              onClose: () => {
                this.$router.push({
                  path: "/luckycards/container_order_list",
                  query: {}
                });
              }
            });
          }
        });
      });
    },
    async cancelOrderFun() {
      showConfirmDialog({
        message: "是否确认要取消？",
        confirmButtonColor: "#00C4A1"
      }).then(() => {
        var postParams = {
          url: "/apis/card-wallet/shipping_order/cancel",
          method: "post",
          props: {
            postage_order_no: this.postage_order_no
          }
        };
        postOrder(postParams.url, postParams.props).then(res => {
          console.log(res);
          if (res.status == 0) {
            showToast({
              type: "text",
              message: "取消成功",
              onClose: () => {
                window.location.reload();
              }
            });
          }
        });
      });
    },
    callWeixinPay(json) {
      const loadStatusData = setInterval(() => {
        // eslint-disable-next-line no-undef
        if (WeixinJSBridge !== undefined) {
          clearInterval(loadStatusData);
          // eslint-disable-next-line no-undef
          WeixinJSBridge.invoke("getBrandWCPayRequest", json, res => {
            // eslint-disable-next-line no-undef
            WeixinJSBridge.log(res.err_msg);
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              this.PaySuccessBack();
            } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
              showToast("取消支付");
            } else {
              mdToast(res.err_msg);
            }
          });
        }
      }, 300);
    },
    PaySuccessBack() {
      mdToast("支付成功");
      setTimeout(() => {
        window.location.reload();
      }, 300);
    },
    // 确认支付
    confirmPay() {
      let platform = '';
      if (this.env.isWeixin()) {
        platform = 'weixin';
      } else if (this.env.isWeibo()) {
        platform = 'weibo';
      } else {
        platform = 'alipay';
      }
      if (!this.env.isWeixin() && !this.env.isWeibo()) {
        if (this.pay_resource == 'weixin_mina') {
          platform = 'weixin';
        } else {
          platform = 'alipay';
        }
      }
      let channel = 'pc';
      if (sessionStorage.getItem("miniProgram") || this.env.isWxApp()) {
        channel = 'miniprogram';
      } else if (this.env.isWeixin()) {
        channel = 'weixin';
      } else if (this.env.isModianIos() || this.env.isModianAndroid() || this.env.isModianIosNew() || this.env.isMobile()) {
        channel = 'wap';
      } else {
        channel = 'pc';
      }
      var postParams = {
        url: "/apis/card-wallet/shipping_order/send_pay",
        method: "post",
        props: {
          postage_order_no: this.postage_order_no,
          platform: platform,
          channel: channel
        }
      };
      postOrder(postParams.url, postParams.props).then(res => {
        if (res.status == 0) {
          this.deliverVisible = false;
          if (this.pay_resource == 'weixin_mina') {
            window.location.href = res.data.data;
          } else if (this.env.isModianIos() || this.env.isModianAndroid()) {
            this.iframe = true;
            setTimeout(() => {
              let iframeWin = document.querySelectorAll("iframe")[0].contentWindow;
              document.querySelectorAll("iframe")[0].style.cssText += "position: fixed;display: block;border: 0px;top: 44px;left: 0px;width: 100vw;z-index: -999;height: 0;";
              iframeWin.postMessage({
                data: res.data.data,
                type: "alpay",
                dataType: res.data.dataType
              }, "*");
            }, 100);
          } else if (res.data.dataType == "URL") {
            window.location.href = res.data.data;
          } else if (res.data.dataType == "FORMDATA") {
            this.html = res.data.data;
            setTimeout(() => {
              document.forms[0].submit();
            });
          } else {
            window.location.href = res.data.data;
          }
          this.ifPayDown = true;
        } else {
          mdToast(res.message);
        }
      });
    },
    showBigImage(list, index) {
      this.bigList = [];
      list.forEach(ele => {
        ele.showBackImg = false;
        this.bigList.push(ele);
      });
      this.bigList = list;
      this.bigInitIndex = index;
      this.bigImageVisible = true;
    },
    copy() {
      document.getElementsByTagName("input")[0].select();
      try {
        if (document.execCommand("copy", false, null)) {
          showSuccessToast("复制成功");
        } else {
          // IOS不支持时候重新初始化Clipboard
          var copyTarget = this.$refs.copyboard;
          var copyboard = new ClipboardJS(copyTarget, {
            text(copyTarget) {
              return copyTarget.getAttribute("copyData");
            }
          });
          copyboard.on("success", () => {
            showSuccessToast("复制成功");
          });
        }
      } catch (err) {
        showToast(err);
      }
    }
  }
};